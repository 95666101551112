<template>
  <v-app style="background-color: #f0f1f4">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-card style="margin-top: 10vh; min-height: 200px; py-10">
            <h1 class="text-center py-8">Gmail ашиглаж нэвтэрнэ үү!</h1>
            <v-card-actions>
              <v-layout justify-center>
                <v-btn
                  v-if="loadingGoogle == false"
                  large
                  style="background-color:#23B739; color:white"
                  @click="googleSignIn"
                >
                  Login with Google
                </v-btn>
                <button v-else type="button" disabled>
                  <span class="spinner-border spinner-border-sm" />
                  Loading..
                </button>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
const fb = require("../firebaseConfig.js");
export default {
  name: "Login",
  data() {
    return {
      loadingGoogle: false,
      loading: false,
    };
  },
  methods: {
    googleSignIn() {
      this.loadingGoogle = true;
      fb.auth
        .signInWithPopup(fb.googleProvider)
        .then((result) => {
          var user = result.user;

          this.$store.commit("setCurrentUser", user);

          fb.usersCollection
            .doc(user.uid)
            .get()
            .then((userData) => {
              if (userData.exists) {
                this.$store.dispatch("fetchUserProfile");
                this.performingRequest = false;
                this.$router.push("/dashboard");
              } else {
                console.log("AAAA");
                fb.usersCollection
                  .doc(user.uid)
                  .set({
                    name: user.displayName,
                    email: user.email,
                    provider: "google",
                    createdAt: new Date(),
                  })
                  .then(() => {
                    this.$store.dispatch("fetchUserProfile");
                    this.performingRequest = false;
                    this.$router.push("/");
                  })
                  .catch((err) => {
                    console.log(err);
                    this.performingRequest = false;
                    this.errorMsg = err.message;
                  });
              }
              this.loadingGoogle = false;
            });
        })
        .catch(function (error) {
          this.loadingGoogle = false;
          console.log(error);
          // Handle Errors here.
          // The email of the user's account used.
          // The firebase.auth.AuthCredential type that was used.
          this.$store.dispatch("setEnabledFalse");
          // ...
        });
    },
  },
};
</script>
